$font-size: 18px;
$font-color: var(--bg-tint-dark);

.box {
	display: flex;
	width: 100%;
	justify-content: space-between;
	align-items: flex-start;
	margin-bottom: 30px;

	.logoBox {
		display: flex;
		justify-content: center;
		align-items: center;
		background: var(--antd-blue-opacity);
		padding: 7px;
		border-radius: 5px;

		.wrapper {
			display: flex;
			flex-direction: column;
			margin-left: 10px;

			.abbreviation, 
			.serviceName {
				color: $font-color;
				font-size: $font-size;
				margin: 0;
			}

			.abbreviation {
				font-size: 20px;
				font-weight: 700;
				letter-spacing: 8.5px;
			}

			.serviceName {
				font-weight: 600;
				font-size: 13px;
			}
		}
	}

	
}