@import './zIndex.scss';
@import './mixins/antdMixins.scss';
@import './mixins/basicMixins.scss';

$transition-time: 0.5s;
$transition-time-tact: 0.2s;
$transition: $transition-time ease-in-out;
$transition-tact: $transition-time-tact ease-in;
$sidebar-width: 80px;
$minific-map-width: 300px;
$panel-width: 312px;
$info-panel-width: 500px;
$notification-panel-width: 500px;
$notification-panel-right: 110px;
$b-radius: 6px;
$detailed-height: 82vh;
$input-height: 40px;
$input-padding: 5px;
$widget-padding: 1rem 1.5rem;
$simple-accordion-width: 245px;
$box-shadow: 1px 1px 20px #0003;
$box-shadow-lite: 1px 1px 10px #afadb352;
$box-shadow-primary: 1px 1px 20px var(--antd-blue);
$inputs-filter: invert(0%) sepia(1%) saturate(21%) hue-rotate(314deg)
  brightness(119%) contrast(74%);
$svg-icons-filter: invert(0%) sepia(1%) saturate(21%) hue-rotate(314deg)
  brightness(350%) contrast(74%);
$table-row-hover-filter: invert(15%) sepia(3%) saturate(90%) hue-rotate(190deg)
  brightness(99%) contrast(99%);
$map-btns-right: 50px;

/* media-sizes */
$media-xxl: 1800px;
$media-xl: 1600px;
$media-l: 1280px;
$media-m: 1024px;
$media-sm: 900px;
$media-s: 800px;
$media-xs: 640px;

/* indents */
$padding: 1rem;
$margin: 1rem;

/* borders */
$b-radius-bottom: 0 0 $b-radius $b-radius;
$b-radius-top: $b-radius $b-radius 0 0;

%space-between {
  @include flex(space-between, row);

  width: 100%;
}

%btn {
  color: var(--white) !important;
  background: var(--primary) !important;
  border: 1px solid var(--primary) !important;
  border-radius: $b-radius;
  cursor: pointer;
  text-transform: uppercase;

  &:hover {
    color: var(--primary) !important;
    background: var(--white) !important;
  }

  &:active {
    color: var(--white) !important;
    background: var(--primary) !important;
  }
}

%absolute-centered {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
