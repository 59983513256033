@import '../../../assets/styles/variables.scss';

.choice {
  @include flex(center, column, center);

  color: var(--font-color);
  background-color: var(--bg);
  margin-top: 20px;
  text-align: center;
  gap: 10px;

  h1,
  h4 {
    color: var(--font-color);
    padding: 0;
    margin: 0;
  }

  h1 {
    margin-bottom: 3rem;
  }

  ul {
    list-style: none;
    padding-inline-start: 0;
    text-align: left;

    li {
      transition: $transition;
      border-radius: $b-radius;

      button {
        @include flex(flex-start, row, flex-start);

        width: 100%;
        background: none;
        padding: $widget-padding;
        gap: 20px;
        border: none;
        outline: none;
        cursor: pointer;
        text-align: left;
      }

      &:not(:last-child) {
        margin-bottom: 30px;

        button {
          cursor: not-allowed;
        }
      }

      &:hover {
        color: var(--whity);
        background-color: var(--grey200);

        h4 {
          color: var(--whity);
        }

        &:last-child {
          color: var(--whity);
          background-color: var(--sidebar-btn-light);
        }
      }

      svg {
        height: 100%;
        width: 40px;
      }

      p {
        padding: 0;
        margin: 0;
      }
    }
  }

  .rowInfo {
    opacity: 0.7;
  }
}
