.main {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  width: 100vw;
  background: var(--grey200);

  .formContainer {
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    height: 600px;
    border-radius: 5px;

    // justify-content: center;
    width: 500px;
    background: var(--bg);
    padding: 30px;

    .content {
      display: flex;
      justify-content: center;
      align-items: center;
      height: 100%;
      width: 100%;
    }
  }
}