.logout {
	position: absolute;
	top: -10px;
	right: 20px;
	display: flex;
	align-items: center;
	height: min-content!important;
	margin-top: 50px;
	padding: 5px 10px!important;

	.text {
		display: block;
		text-wrap: wrap;
		width: 100px;
		text-align: left;
		font-size: 15px;
		line-height: 20px;
		margin-bottom: 0;
		margin-right: 15px;
	}
	
	.icon {
		margin-right: -7px;
	}
}