@import '../../../assets/styles/variables.scss';

.container {
  @include flex;

  height: 100%;
  background-color: var(--bg);

  label {
    color: var(--font-color) !important;
  }
}

.wrapper {
  width: 340px;
}

.btn {
  width: 100%;
  color: var(--white);
  background: var(--primary);
  font-size: 1.2rem;
  font-weight: 700;
  border-radius: 8px;
  border: 1px solid var(--primary);
  text-transform: uppercase;
}

.radioRow {
  display: flex;
  justify-content: space-between;

  label {
    color: var(--font-color);
  }

  a {
    text-decoration: underline;
  }
}

.register {
  display: flex;
  justify-content: flex-end;
  text-decoration: underline;
}

.inputContainer {
  & div > label {
    color: var(--grey100);
    font-size: 1.2rem;
  }

  &.icon {
    stroke: var(--grey100);
  }

  .inputWrapper {
    display: flex;
    align-items: center;
    width: 100%;
    background-color: var(--bg);
    padding: 5px;
    border: 1px solid var(--primary);
    border-radius: 8px;
    cursor: text;
  }

  .input {
    display: inline-block;
    width: 100%;
    color: var(--font-color);
    background-color: var(--bg);
    margin-left: 8px;
    border: none;
    outline: none;

    &:-webkit-autofill {
      color: var(--font-color);
      box-shadow: inset 0 0 0 50px var(--bg);
      -webkit-text-fill-color: var(--font-color);
    }
  }
}
