.form {
	.head {
		font-weight: 600;
	}
	.title {
		margin-bottom: 30px;
		text-align: left;
	}

	.dsc {
		display: flex;
		align-items: center;
		background: var(--antd-blue-opacity8a);
		padding: 5px;
		margin-bottom: 10px;
		border-radius: 5px;

		.icon {
			margin-right: 10px;
		}

		.dscText {
			margin-bottom: 0;
		}
	}

	.list {
		max-height: 200px;
		margin-bottom: 30px;
		overflow-y: auto;

		.item {
			display: flex;
			justify-content: flex-start;
			align-items: center;
			padding: 10px 0;

			span {
				margin-left: 7px;
			}
		}
	}
}