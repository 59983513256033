.container {
  display: flex;
  flex-direction: column;

  svg {
    margin: 10% 0;
  }
}

.logo {
  height: 50px;
  width: 47px;
}

.itc {
  height: 51px;
  width: 145px;
  fill: var(--logo-sign-itc);
}

.sber {
  height: 39px;
  width: 144px;
  fill: var(--logo-sign-sber);
}

.logoSmall {
  height: 43px;
  width: 42px;
}

.logoOsut {
  height: 50px;
}

.itcSmall {
  height: 15px;
  width: 41px;
}

.sberSmall {
  height: 11px;
  width: 41px;
}
